import { OneTrustProvider } from '@grandvisionhq/cookie-consent'
import { createLocalRouteFormatter } from '@grandvisionhq/frk-routing'
import { AppComponentOptions, createAppComponent } from '@grandvisionhq/www-next/pages/app'

import {
  getAddressConfig,
  getAdyenConfig,
  getApiConfig,
  getAuthConfig,
  getGoogleMapsConfig,
} from '../configuration'
import { featureToggles } from '../configuration/feature-toggles'
import { INTL_DEFAULTS, intlConfigMap as intl } from '../configuration/intl'
import { localRouteFormatters } from '../configuration/manifest'

import '@grandvisionhq/iris-ui/themes/be-pearle.theme.css'
import '@grandvisionhq/iris-ui/style.css'
import '../styles/index.scss'

const settings: AppComponentOptions['settings'] = {
  intl,
  featureToggles,
  routing: {
    formatters: [createLocalRouteFormatter({ formatters: localRouteFormatters })],
  },
  address: getAddressConfig(),
  auth: getAuthConfig(),
  adyen: getAdyenConfig(),
  apiConfig: getApiConfig(),
  googleMaps: getGoogleMapsConfig(),
}

const App = await createAppComponent({
  defaultLocale: INTL_DEFAULTS.locale,
  consentProvider: OneTrustProvider,
  settings,
})

export default App
